import { FC, SetStateAction, useState } from "react";
import Icon from "./icon";

interface Props {
  password: string;
  className?: string; 
  name?: string;
  setPassword: (value: SetStateAction<string>) => void;
  disabled?: boolean,
  autoComplete?: string
}

export const PasswordField: FC<Props> = ({
  password,
  className,
  name,
  setPassword,
  disabled,
  autoComplete
}): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  return (
   
    <div className="relative">
      <input 
          id={name || "password"}
          name={name || "password"} 
          className={className || "form-input w-full"} 
          type={visible ? "text" : "password"}
          value={password} 
          onChange={e => setPassword(e.target.value)}
          required
          disabled={disabled}
          autoComplete={autoComplete}
      />
      <div 
      className="absolute inset-0 left-auto flex items-center cursor-pointer"
      onClick={() => setVisible(!visible)}
      >
        { visible 
          ? <Icon.eyeSlash  className="w-7 h-7 shrink-0 ml-3 mr-2" /> 
          : <Icon.eye  className="w-7 h-7 shrink-0 ml-3 mr-2" /> 
        }
      </div>
    </div>
    
  );
};